import { TablePreferences } from '@veneer/core'

export const ProxyConstants = {
  name: 'name',
  proxyName: 'proxyName',
  proxyStatus: 'proxyStatus',
  status: 'status',
  dateAdded: 'dateAdded',
  lastUpdated: 'lastUpdated',
  description: 'description',
  devices: 'devices',
  softwareVersion: 'softwareVersion',
  hostName: 'hostName',
  descending: 'descending',
  ascending: 'ascending',
  pageSize: 25,
  currentPage: 1,
  rowConfig: 'rowConfig'
}

export const defaultpageSizeOptions = [
  { value: 5 },
  { value: 25 },
  { value: 50 },
  { value: 100 },
  { value: 500 }
]

export const defaultOrderColumn = [
  'name',
  'proxyStatus',
  'lastUpdated',
  'dateAdded',
  'devices',
  'pHostName',
  'pDescription'
]

export const preferences: TablePreferences = {
  width: [
    {
      columnId: 'name',
      width: 230
    },
    {
      columnId: 'proxyStatus',
      width: 130
    },
    {
      columnId: 'pDescription',
      width: 150
    },
    {
      columnId: 'devices',
      width: 90
    },
    {
      columnId: 'dateAdded',
      width: 210
    },
    {
      columnId: 'lastUpdated',
      width: 210
    },
    {
      columnId: 'softwareVersion',
      width: 150
    },
    {
      columnId: 'pHostName',
      width: 150
    }
  ]
}
export const ProxyStatus = [
  { value: 'Online', label: 'statusOnline' },
  { value: 'Offline', label: 'statusOffline' }
]

export const ProxyStatusValues = ProxyStatus.map((item) =>
  item.value.toLowerCase()
)
