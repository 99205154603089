import React, { useEffect, useState } from 'react'
import { Button, IconSearch, Search } from '@veneer/core'
import { SearchProxyProps } from './type'
import { useShellRootContext } from '../../contexts/ShellRoot'

const SearchProxy = (props: SearchProxyProps) => {
  const { t } = useShellRootContext()
  const {
    isLoading,
    searchProxyFlag,
    showMobileSearchBox,
    showSearchButton,
    setSearchProxyFlag,
    setSearchProxyItem,
    setSearchProxyValue,
    setShowMobileSearchBox
  } = props

  const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState(window.innerWidth)
    const callback = () => setWindowSize(window.innerWidth)

    useEffect(() => {
      window.addEventListener('resize', callback)
      return () => window.removeEventListener('resize', callback)
    }, [])

    return windowSize
  }

  const resolution = useWindowSize()
  const isMobileView = resolution <= 600

  const getBackground = () => {
    return showMobileSearchBox ? '#0076AD1A' : 'transparent'
  }

  const searchButton = (
    <Button
      appearance="ghost"
      customStyle={{ background: getBackground() }}
      data-testid="proxys-mobile-screen-search-button"
      disabled={isLoading}
      leadingIcon={<IconSearch />}
      onClick={() => setShowMobileSearchBox(!showMobileSearchBox)}
    />
  )

  const searchProxy = (
    <Search
      content={undefined}
      data-testid="proxys-search-box"
      disabled={isLoading}
      nonce={undefined}
      onChange={(value) => setSearchProxyValue(value)}
      onClear={() => {
        setSearchProxyItem('')
        setSearchProxyFlag(!searchProxyFlag)
      }}
      placeholder={t('ecp-fleet-proxies.search', 'Search')}
      rel={undefined}
      rev={undefined}
    />
  )

  return (
    <>
      {isMobileView && showSearchButton && searchButton}
      {!isMobileView && showSearchButton && searchProxy}
      {isMobileView && showMobileSearchBox && !showSearchButton && searchProxy}
    </>
  )
}

export default SearchProxy
