import styled from 'styled-components'
import primitives from '@veneer/primitives'

export const AddProxyModalFooter = styled.div`
  padding-top: ${primitives.layout.size5}px;
  label {
    margin-bottom: 15px;
  }
`
export const AddProxyModalDescr = styled.div`
  padding-top: ${primitives.layout.size5}px;
  span.hostName {
    font-weight: 700;
    margin: 0 5px;
  }
`
export const AddProxyModalNote = styled.div`
  padding-top: ${primitives.layout.size4}px;
`
export const RegProxyName = styled.div`
  font-weight: 800;
`
export const SuccessIconStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 21px;
  padding-right: 20px;
  padding-left: 20px;
`
