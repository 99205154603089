// eslint-disable-next-line no-restricted-imports
import { TableI18n } from '@veneer/core'
import { useMemo } from 'react'
import { useShellRootContext } from '../../contexts/ShellRoot'

const Retrievei18nItems = (): TableI18n => {
  const { t } = useShellRootContext?.() || {}
  const retrieveValue = useMemo(() => {
    return {
      actionButton: '',
      cancelButton: t('ecp-fleet-proxies.cancel', 'Cancel'),
      clearAllFilters: '',
      columnOptions: t('ecp-fleet-proxies.columnOptions', 'Column Options'),
      columnResizeTooltip: '',
      deselectAllItems: '',
      downButton: '',
      itemSelected: '',
      itemsSelected: t('ecp-fleet-proxies.itemsSelected', 'Items selected'),
      noItems: '',
      resetToDefault: t('ecp-fleet-proxies.resetToDefault', 'Reset to Default'),
      saveButton: t('ecp-fleet-proxies.save', 'Save'),
      selectAllItems: '',
      selectAllPageItems: '',
      sortedAscending: '',
      sortedDescending: '',
      upButton: '',
      sortByAscending: '',
      sortByAscendingActive: '',
      sortByDescending: '',
      sortByDescendingActive: ''
    }
  }, [t])
  return retrieveValue
}

export default Retrievei18nItems
