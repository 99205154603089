/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react'
import { Button, useToast, IconDownload } from '@veneer/core'
import FilterSidebar from '../../shared-components/FilterSidebar/FilterSidebar'
import {
  ActionArea,
  ActionAreaElements,
  ActionAreaFilterTags,
  Container,
  SearchForm,
  StyledForm,
  StyledTag
} from './styles'
import ProxiesData from '../Proxies/Proxies'
import { useShellRootContext } from '../../contexts/ShellRoot'
import AddProxyModal from '../../shared-components/Modals/AddProxyModal'
import {
  getProxiesList,
  registerProxy,
  getProxiesForWex,
  approveUserCodeForPairing,
  registerProxyForWex
} from '../../utils/api'
import { Proxies } from '../../interfaces/proxyInterface'
import { setProps } from '../../utils/commonMethods'
import * as T from './type'
import AddProxy from './AddProxy'
import FilterProxies from './FilterProxies'
import SearchProxy from './SearchProxy'

const ProxiesMainPage = ({ ...props }: T.FleetProxieListProps) => {
  setProps(props)

  const isWexTheme = props.themeIdentifier && props.themeIdentifier === 'wex'
  const urlParams = new URLSearchParams(window.location.search)
  const user_code = urlParams.get('user_code')
  const { t = (key, defaultValue) => defaultValue || key, interfaces } =
    useShellRootContext?.() || {}
  const authStack = interfaces?.v1?.app?.getAuthStack?.()
  const authProvider = interfaces?.v2.authProvider || {}
  const events = interfaces?.v1?.events
  const navigation = interfaces?.v1?.navigation
  const { addToast } = useToast?.() || {}
  const [showProxyFilterSidebar, setShowProxyFilterSidebar] =
    useState<boolean>(false)
  const [searchProxyItem, setSearchProxyItem] = useState<string>('')
  const [searchProxyItems, setSearchProxyItems] = useState<string[]>(
    props.searchProxyItems || []
  )
  const [searchProxyValue, setSearchProxyValue] = useState<string>('')
  const [searchProxyFlag, setSearchProxyFlag] = useState<boolean>(false)
  const [showAddProxyModal, setShowAddProxyModal] = useState<boolean>(
    user_code ? true : false
  )
  const [proxies, setProxies] = useState<Array<Proxies>>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [addModalLoading, setAddModalLoading] = useState<boolean>(false)
  const [hasError, setHasError] = useState<boolean>(false)
  const [proxyVerify, setProxyVerify] = useState<boolean>(false)
  const [proxyConnect, setProxyConnect] = useState<boolean>(false)
  const [isProxyRegisteredAndConnect, setIsProxyRegisteredAndConnect] =
    useState<boolean>(false)
  const [showMobileSearchBox, setShowMobileSearchBox] = useState<boolean>(false)

  const tagKey = (subkey: string): string => {
    return (
      t(`ecp-fleet-proxies.connectivity`, 'Connectivity') +
      ' : ' +
      t(`ecp-fleet-proxies.${subkey}`, subkey)
    )
  }

  const getEntireProxiesList = async (
    currentPage = 0,
    size = 50,
    entireProxies = []
  ) => {
    const response = await getProxiesList(
      authProvider,
      authStack,
      currentPage,
      size,
      ''
    )

    if (response === '') {
      return entireProxies
    } else {
      const { totalPages, content } = response
      entireProxies = entireProxies.concat(content)
      if (totalPages - 1 > currentPage) {
        await new Promise((resolve) => setTimeout(resolve, 200))
        return await getEntireProxiesList(currentPage + 1, size, entireProxies)
      } else {
        return entireProxies
      }
    }
  }

  const retriveProxiesList = async () => {
    try {
      setIsLoading(true)
      let entireProxiesList
      if (isWexTheme) {
        entireProxiesList = await getProxiesForWex()
      } else {
        entireProxiesList = await getEntireProxiesList()
      }
      setProxies(entireProxiesList)
      setIsLoading(false)
      setHasError(false)
    } catch (e) {
      setIsLoading(false)
      setHasError(true)
    }
  }

  useEffect(() => {
    retriveProxiesList()
  }, [])

  useEffect(() => {
    urlParams.delete('user_code')
  }, [user_code])

  useEffect(() => {
    events.addEventListener('ecp-banner-reload-call', retriveProxiesList)
    return () => {
      events.removeEventListener('ecp-banner-reload-call', retriveProxiesList)
    }
  }, [events, retriveProxiesList])

  const openToast = useCallback(
    (itemName, error) => {
      const type = error ? 'negative' : 'positive'
      const id: string = error ? 'error-remove' : 'success'
      let text: string
      if (error) {
        text = itemName + '' + t('ecp-fleet-proxies.failed', 'failed')
      } else {
        text =
          itemName +
          ' ' +
          t('ecp-fleet-proxies.successfullyAdded', 'successfully added.')
      }
      addToast({
        id: id,
        type: type,
        text: text
      })
    },
    [addToast]
  )

  const handlePerformFilter = (value1: string, value2: string) => {
    if (value2 === 'push') {
      setSearchProxyItems([...searchProxyItems, value1])
    }
    if (value2 === 'pop') {
      setSearchProxyItems(searchProxyItems.filter((item) => item !== value1))
    }
    if (value2 === 'clearall') {
      setSearchProxyItems([])
    }
  }

  const handleProxyFilter = (e: { preventDefault: () => void }) => {
    e.preventDefault()
    setIsLoading(true)
    setSearchProxyItem(searchProxyValue)
    setSearchProxyFlag(!searchProxyFlag)
    setTimeout(() => {
      setIsLoading(false)
    }, 1000)
  }

  /* istanbul ignore next */
  const handleDownloadTrayAppClick = () => {
    props.onDownloadProxyChange(!props.downloadProxyApp)
  }

  const proxyTableActionArea = () => {
    return (
      <ActionArea data-testid="proxies-component-actionarea">
        <ActionAreaElements>
          <SearchForm data-testid="proxy-list-search-form">
            <form
              onSubmit={handleProxyFilter}
              data-testid="proxy-search-form"
            >
              <SearchProxy
                isLoading={isLoading}
                searchProxyFlag={searchProxyFlag}
                showMobileSearchBox={showMobileSearchBox}
                showSearchButton={true}
                setSearchProxyFlag={setSearchProxyFlag}
                setSearchProxyItem={setSearchProxyItem}
                setSearchProxyValue={setSearchProxyValue}
                setShowMobileSearchBox={setShowMobileSearchBox}
              />
            </form>
            <FilterProxies
              isLoading={isLoading}
              searchProxyItems={searchProxyItems}
              setShowProxyFilterSidebar={setShowProxyFilterSidebar}
            />
          </SearchForm>
          {isWexTheme && (
            <Button
              appearance="ghost"
              leadingIcon={<IconDownload />}
              disabled={isLoading}
              onClick={handleDownloadTrayAppClick}
              data-testid="download-tray-app-button"
            >
              {t('ecp-fleet-proxies.fleetProxyInstaller', 'WEX Fleet Proxy')}
            </Button>
          )}
          <AddProxy
            isLoading={isLoading}
            setShowAddProxyModal={setShowAddProxyModal}
          />
        </ActionAreaElements>
        {searchProxyItems && (
          <ActionAreaFilterTags>
            {searchProxyItems.map((item: string, index: number) => (
              <StyledTag
                colorScheme="lavender"
                className={isWexTheme ? 'wex-tag' : ''}
                isTint
                id="proxies-filter-tags"
                key={`${item}-${index}`}
                data-testid={`${item}-${index}-tag`}
                label={tagKey(item)}
                clearButton
                onClick={() => {
                  searchProxyItems.splice(index, 1)
                  retriveProxiesList()
                }}
              />
            ))}
            {searchProxyItems.length > 1 && (
              <Button
                id="clear-all-tags"
                data-testid="clear-all-tags"
                appearance="ghost"
                small
                onClick={() => {
                  searchProxyItems.splice(0, searchProxyItems.length)
                  retriveProxiesList()
                }}
              >
                {t('ecp-fleet-proxies.clearAll', 'Clear All')}
              </Button>
            )}
          </ActionAreaFilterTags>
        )}
        {showMobileSearchBox && (
          <StyledForm
            onSubmit={handleProxyFilter}
            data-testid="proxy-search-form-mobile"
          >
            <SearchProxy
              isLoading={isLoading}
              searchProxyFlag={searchProxyFlag}
              showMobileSearchBox={showMobileSearchBox}
              showSearchButton={false}
              setSearchProxyFlag={setSearchProxyFlag}
              setSearchProxyItem={setSearchProxyItem}
              setSearchProxyValue={setSearchProxyValue}
              setShowMobileSearchBox={setShowMobileSearchBox}
            />
          </StyledForm>
        )}
      </ActionArea>
    )
  }

  const onAddProxy = useCallback(
    async (proxyName, proxyDescription, proxyHost, pinCode) => {
      setAddModalLoading(true)
      try {
        if (isWexTheme) {
          await registerProxyForWex(proxyName, proxyDescription, proxyHost)
        } else {
          await registerProxy(
            proxyName,
            proxyDescription,
            proxyHost,
            authProvider,
            authStack
          )
          await approveUserCodeForPairing(pinCode, authProvider, authStack)
        }
        setShowAddProxyModal(false)
        openToast(proxyName, false)
      } catch (error) {
        openToast(proxyName, true)
      } finally {
        setAddModalLoading(false)
        setProxyVerify(false)
        setProxyConnect(false)
        setIsProxyRegisteredAndConnect(false)
        retriveProxiesList()
        navigation.push('/proxies')
      }
    },
    [openToast, retriveProxiesList]
  )

  /* istanbul ignore next */
  const checkProxyRegistrationAndConnectionStatus = useCallback(
    async (proxyName, pinCode) => {
      setAddModalLoading(true)
      try {
        await approveUserCodeForPairing(pinCode, authProvider, authStack)
        setShowAddProxyModal(false)
        openToast(proxyName, false)
      } catch (error) {
        openToast(proxyName, true)
      } finally {
        setAddModalLoading(false)
        setProxyVerify(false)
        setProxyConnect(false)
        setIsProxyRegisteredAndConnect(false)
        retriveProxiesList()
        navigation.push('/proxies')
      }
    },
    [openToast, retriveProxiesList]
  )
  const closeAddProxyModal = async () => {
    setShowAddProxyModal(false)
    navigation.push('/proxies')
  }

  return (
    <Container data-testid="proxies-list">
      {showProxyFilterSidebar && (
        <FilterSidebar
          data-testid="proxy-filter-side-bar"
          onclose={() => setShowProxyFilterSidebar(false)}
          performFilter={(value1: string, value2: string) =>
            handlePerformFilter(value1, value2)
          }
          appliedFilter={searchProxyItems}
        />
      )}
      <ProxiesData
        actionArea={proxyTableActionArea()}
        data-testid="proxies-list-component"
        hasError={hasError}
        proxies={proxies}
        isLoading={isLoading}
        searchProxyItem={searchProxyItem}
        searchProxyItems={searchProxyItems}
        retriveProxies={retriveProxiesList}
        isWexTheme={isWexTheme}
      />
      <AddProxyModal
        data-testid="add-proxy-modal"
        modalTitle={t('ecp-fleet-proxies.verifyProxy', 'Verify a Proxy')}
        onAddProxy={onAddProxy}
        proxyRegistrationAndConnectionStatus={
          checkProxyRegistrationAndConnectionStatus
        }
        proxies={proxies}
        onClose={() => {
          closeAddProxyModal()
        }}
        showModal={showAddProxyModal}
        isLoading={addModalLoading}
        proxyVerify={proxyVerify}
        proxyConnect={proxyConnect}
        isProxyRegisteredAndConnect={isProxyRegisteredAndConnect}
        userCode={user_code}
        isWexTheme={isWexTheme}
      />
    </Container>
  )
}

export default ProxiesMainPage
