import { ProxyList } from 'src/interfaces/proxyInterface'
import { FleetProxieListProps } from '../components/ProxiesMainPage/type'
import { ProxyStatusValues } from './constants'
import dayjs from 'dayjs'
let localprops: FleetProxieListProps = {
  language: 'en',
  country: 'US'
}

export const setProps = (props) => {
  localprops = props
}

export const getProps = (): FleetProxieListProps => {
  return localprops
}

export const filter = (
  proxyListData: Array<ProxyList>,
  searchItem: string,
  searchItems: string[]
) => {
  if (searchItem.length === 0 && searchItems.length === 0) {
    return proxyListData
  }
  const filterdata = proxyListData
    .filter((proxy) => {
      if (searchItem.length != 0) {
        return (
          proxy?.proxyName?.toLowerCase().includes(searchItem.toLowerCase()) ||
          proxy?.connectionState.status
            ?.toLowerCase()
            .includes(searchItem.toLowerCase()) ||
          proxy?.hostName?.toLowerCase().includes(searchItem.toLowerCase()) ||
          proxy?.description?.toLowerCase().includes(searchItem.toLowerCase())
        )
      } else {
        return proxy
      }
    })
    .filter((proxy) => {
      if (searchItems.length != 0) {
        if (searchItems.some((item) => ProxyStatusValues.includes(item))) {
          return searchItems.some((value) =>
            proxy?.connectionState.status.toLowerCase().includes(value)
          )
        } else {
          return proxy
        }
      } else {
        return proxy
      }
    })
  return filterdata
}

export const selectRow = (
  dataArray: ProxyList[],
  checked: boolean,
  id?: number
) => {
  const updatedDataArray = [...dataArray]
  const index = updatedDataArray.findIndex((row) => row.proxyId === id)
  updatedDataArray[index].rowConfig.selected = checked
  return updatedDataArray
}

export const handleMultipleProxies = (
  dataArray: ProxyList[],
  checked: boolean
) => {
  return dataArray.map((row) => {
    const newRow = row
    newRow.rowConfig.selected = checked
    return newRow
  })
}

export const selectionState = (dataArray: ProxyList[]) => {
  const total = dataArray.length
  const selected = dataArray.filter((row) => row.rowConfig.selected).length

  switch (selected) {
    case 0:
      return 'none'
    case total:
      return 'all'
    default:
      return 'indeterminated'
  }
}

export const sortTable = (object1, object2, id, value) => {
  let fieldA = object1[id]
  let fieldB = object2[id]

  if (typeof fieldA === 'string' && typeof fieldB === 'string') {
    if (!isNaN(Date.parse(fieldA)) && !isNaN(Date.parse(fieldB))) {
      fieldA = new Date(fieldA)
      fieldB = new Date(fieldB)
    } else {
      fieldA = fieldA.toLowerCase()
      fieldB = fieldB.toLowerCase()
    }
  } else if (
    (fieldA === null || fieldB === null) &&
    typeof fieldA !== typeof fieldB
  ) {
    if (fieldA === null) return -value
    if (fieldB === null) return value
  }
  if (!isNaN(fieldA) && !isNaN(fieldB)) {
    fieldA = Number(fieldA)
    fieldB = Number(fieldB)
  }

  if (fieldB < fieldA) {
    return -value
  }
  if (fieldB > fieldA) {
    return value
  }
  return 0
}

export const getFormatedDate = (abc: string) => {
  const formatedDate = dayjs(abc).format('DD MMM, YYYY hh:mm A')
  return formatedDate
}

export const jsonParse = (text: string, defResult = []) => {
  try {
    return text ? JSON.parse(text) : defResult
  } catch (SyntaxError) {
    return defResult
  }
}
