import styled from 'styled-components'
import primitives from '@veneer/primitives'
import { Modal, ButtonGroup } from '@veneer/core'

export const StyledModal = styled(Modal)<{
  customModalWidth: string
  customModalDescWidth: string
}>`
  .vn-modal--content {
    width: ${({ customModalWidth }) => customModalWidth};
    max-width: ${({ customModalWidth }) => customModalWidth};
  }
  .body-large {
    width: ${({ customModalDescWidth }) => customModalDescWidth};
    max-width: ${({ customModalDescWidth }) => customModalDescWidth};
  }
  .hideButton {
    display: none;
  }
  button {
    padding: 8px 20px;
  }
`

export const VeneerButtonGroup = styled(ButtonGroup)`
  .swapBtnPosition {
    justify-content: space-between;
    width: 632px;
    flex-direction: row-reverse;
  }
`

export const ModalHeader = styled.div`
  align-items: flex-start;
  column-gap: ${primitives.layout.size2}px;
  display: flex;
`

export const ModalHeaderIcon = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  svg {
    width: 46px;
    height: 46px;
  }
`
