/* eslint-disable no-restricted-imports */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  UIEvent
} from 'react'
import {
  TableSortBy,
  SortTypes,
  PageSizeInterface,
  IconCircle,
  useToast,
  Table
} from '@veneer/core'
import ContextualFooter from './contextualFooter'
import { ProxyList } from '../../interfaces/proxyInterface'
import {
  selectRow,
  filter,
  handleMultipleProxies,
  selectionState,
  getFormatedDate,
  jsonParse,
  sortTable
} from '../../utils/commonMethods'
import {
  ProxyConstants,
  defaultOrderColumn,
  defaultpageSizeOptions,
  preferences
} from '../../utils/constants'
import Retrievei18nItems from './Retrievei18nItems'
import RemoveModal from '../../shared-components/Modals/RemoveModal'
import UpdateProxyModal from '../../shared-components/Modals/UpdateProxyModal'
import {
  removeProxiesForWex,
  removeProxiesList,
  updateProxy,
  updateProxyForWex
} from '../../utils/api'
import { ProxyStatus, ColumnPiece, ProxyName, ClickToAddProxy } from './styles'
import { useShellRootContext } from '../../contexts/ShellRoot'
import { ErrorWidget, SmartTooltip } from '@jarvis/react-portal-addons'
const defSortOrder: SortTypes = 'ascending'
const defSorting: TableSortBy = {
  id: 'dateAdded',
  type: defSortOrder
}
const ProxiesData = ({
  actionArea,
  proxies,
  searchProxyItem,
  searchProxyItems,
  isLoading,
  hasError,
  retriveProxies,
  isWexTheme
}) => {
  const proxiesTableColumns = 'proxyMFEColumns'
  const key = (subkey: string): string => {
    return t(
      `ecp-fleet-proxies.${subkey}`,
      subkey.charAt(0).toUpperCase() + subkey.slice(1)
    )
  }

  const createProxyData = (proxyData: ProxyList[]) => {
    const getTableData = (name: string, dataTestId: string) => {
      return (
        <ColumnPiece>
          <SmartTooltip
            arrow
            content={name}
            contentHideDelayOnHover={0}
            contentShowDelayOnHover={0}
            data-testId={`${dataTestId}-tooltip`}
            id={`${dataTestId}-tooltip`}
            placement="bottom"
            portal
            useJsPositioning
          >
            <ProxyName data-testid={dataTestId}>{name}</ProxyName>
          </SmartTooltip>
        </ColumnPiece>
      )
    }
    return proxyData.map((proxy, index) => ({
      ...proxy,
      name: getTableData(proxy.proxyName, 'proxy-name'),
      pName: proxy.proxyName,
      proxyStatus: (
        <ColumnPiece>
          <ProxyStatus data-testid={`proxy-status-${proxy.id}`}>
            <IconCircle
              data-testid="user-status-circle"
              color={getStatusIconColor(
                proxy.connectionState.status.toLowerCase()
              )}
              filled
              size={12}
            />
            <span>{key(proxy.connectionState.status.toLowerCase())}</span>
          </ProxyStatus>
        </ColumnPiece>
      ),
      status: proxy.connectionState.status,
      dateAdded: getFormatedDate(proxy.dateAdded),
      lastUpdated: getFormatedDate(proxy.lastUpdated),
      pDescription: getTableData(proxy.description, 'proxy-description'),
      description: proxy.description,
      devices: proxy.devices,
      softwareVersion: proxy.softwareVersion,
      pHostName: getTableData(proxy.hostName, 'proxy-host-name'),
      hostName: proxy.hostName,
      proxyId: index,
      id: proxy.id,
      rowConfig: {
        selected: false
      }
    }))
  }

  const { t = (key, defaultValue) => defaultValue || key, interfaces } =
    useShellRootContext?.() || {}
  const authStack = interfaces?.v1?.app?.getAuthStack?.()
  const authProvider = interfaces?.v2.authProvider || {}
  const { addToast } = useToast()
  const [currentPage, setCurrentPage] = useState<number>(
    ProxyConstants.currentPage
  )
  const [proxyData, setProxyData] = useState<ProxyList[]>([])
  const [proxyPageData, setProxyPageData] = useState<ProxyList[]>([])
  const [order, setOrder] = useState(
    jsonParse(sessionStorage.getItem(proxiesTableColumns), defaultOrderColumn)
  )
  const [totalItems, setTotalItems] = useState<number>(proxies.length)
  const [pageSize, setPageSize] = useState<number>(ProxyConstants.pageSize)
  const [orderBy, setOrderBy] = useState(defSorting)
  const selectedItems: ProxyList[] = proxyData.filter(
    (data) => data[ProxyConstants.rowConfig].selected
  )
  const [modalLoading, setModalLoading] = useState<boolean>(false)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false)
  const numberOfSelectedItems: number = selectedItems.length

  type ColumnIndexTypes = 'visible' | 'hidden' | 'none'

  type proxyListColumnstype = {
    id: string
    label: string
    index?: ColumnIndexTypes
    required?: boolean
    sortable?: boolean
  }

  const proxyListColumns: Array<proxyListColumnstype> = [
    {
      id: 'name',
      label: t('ecp-fleet-proxies.proxyName', 'Proxy Name'),
      required: true
    },
    {
      id: 'proxyStatus',
      label: t('ecp-fleet-proxies.connectivity', 'Connectivity')
    },
    {
      id: 'lastUpdated',
      label: t('ecp-fleet-proxies.lastUpdated', 'Last Updated')
    },
    {
      id: 'dateAdded',
      label: t('ecp-fleet-proxies.dateAdded', 'Date Added')
    },

    {
      id: 'pDescription',
      label: t('ecp-fleet-proxies.description', 'Description')
    },

    {
      id: 'devices',
      label: t('ecp-fleet-proxies.devices', 'Devices')
    },
    {
      id: 'softwareVersion',
      label: t('ecp-fleet-proxies.fleetProxyVersion', 'Fleet Proxy Version')
    },
    {
      id: 'pHostName',
      label: t('ecp-fleet-proxies.hostName', 'Host Name')
    },
    {
      id: 'proxyId',
      label: 'proxyId',
      index: 'hidden'
    }
  ]

  const getStatusIconColor = (state) => {
    const colorState = {
      online: 'hunterGreen7',
      offline: 'red7'
    }

    return colorState[state?.toLowerCase()]
  }

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const handlePageSizeChange = (
    event: UIEvent<HTMLElement>,
    option: PageSizeInterface
  ) => {
    event.preventDefault()
    setPageSize(option.value)
  }

  useEffect(() => {
    const filterData = filter(proxyData, searchProxyItem, searchProxyItems)
    setTotalItems(filterData.length)
    const currentStartIndex = (currentPage - 1) * pageSize
    const startIndex =
      filterData.length > currentStartIndex ? currentStartIndex : 0
    const pageData: ProxyList[] = filterData.slice(
      startIndex,
      startIndex + pageSize
    )
    setProxyPageData(pageData)
    setTimeout(() => {
      handlePageChange(filterData.length > pageSize ? currentPage : 1)
    }, 100)
  }, [
    proxies,
    isLoading,
    searchProxyItem,
    proxyData,
    pageSize,
    searchProxyItems
  ])

  const setPaginationData = () => {
    const startIndex = (currentPage - 1) * pageSize
    const pageData: ProxyList[] = filter(
      proxyData,
      searchProxyItem,
      searchProxyItems
    ).slice(startIndex, startIndex + pageSize)
    setProxyPageData(pageData)
  }
  const sortableNames = {
    name: 'pName',
    proxyStatus: 'status',
    pDescription: 'description',
    pHostName: 'hostName'
  }
  useEffect(() => {
    const newId = sortableNames[orderBy.id] || orderBy.id
    const getProxyData = createProxyData(proxies).sort((object1, object2) =>
      sortTable(object1, object2, newId, orderBy.type === 'descending' ? 1 : -1)
    )
    setProxyData(getProxyData)
  }, [proxies, isLoading, orderBy])

  useEffect(() => {
    const newId2 = sortableNames[orderBy.id] || orderBy.id
    setProxyPageData(
      proxyData.sort((object1, object2) =>
        sortTable(
          object1,
          object2,
          newId2,
          orderBy.type === 'descending' ? 1 : -1
        )
      )
    )
    setProxyPageData(proxyData)
    setPaginationData()
  }, [proxyData, isLoading, orderBy])

  useEffect(() => {
    setPaginationData()
  }, [currentPage, pageSize])

  const handleSelect = (event: React.ChangeEvent, index: number | string) => {
    setProxyPageData(
      selectRow(proxyData, event.target['checked'], index as number)
    )
    setPaginationData()
  }

  const handleSelectAllOrDeselectAll = (selected: boolean) => {
    handleMultipleProxies(proxyData, selected)
    setPaginationData()
  }

  const handleSelectAllPageItems = (event: React.ChangeEvent) => {
    setProxyPageData(
      handleMultipleProxies(proxyPageData, event.target['checked'])
    )
  }
  const handleOnColumnReorder = (newOrder) => {
    setOrder(newOrder)
    sessionStorage.setItem(proxiesTableColumns, JSON.stringify(newOrder))
  }
  const handleSort = useCallback(
    (
      _: React.MouseEvent<HTMLElement, MouseEvent>,
      { id, type }: TableSortBy
    ) => {
      const regSorting = {
        id: id,
        type: type
      }
      setOrderBy(regSorting)
    },
    []
  )
  const openToast = useCallback(
    (action, itemName, selectedItems, error) => {
      const type = error ? 'negative' : 'positive'
      const id: string = error ? 'error-remove' : 'success'
      let text: string

      if (error) {
        text = 'Failed'
      } else {
        if (action === 'removeProxy') {
          text =
            itemName + ' ' + t('ecp-fleet-proxies.proxiesRemoved', ' removed.')
        } else if (action === 'updateProxy') {
          text =
            itemName +
            ' ' +
            t('ecp-fleet-proxies.successfullySaved', 'Successfully saved')
        } else {
          text = t('ecp-fleet-proxies.success', 'Success')
        }
      }
      addToast({
        id: id,
        type: type,
        text: text
      })
    },
    [addToast]
  )

  /* istanbul ignore next */
  const onDeleteProxy = useCallback(async () => {
    setModalLoading(true)
    try {
      let deletedProxy
      if (isWexTheme) {
        deletedProxy = await removeProxiesForWex(selectedItems[0].id)
      } else {
        deletedProxy = await removeProxiesList(
          selectedItems[0].id,
          authProvider,
          authStack
        )
      }
      if (deletedProxy) {
        openToast(
          'removeProxy',
          selectedItems[0].proxyName,
          selectedItems.length,
          false
        )
        setShowDeleteModal(false)
      }
    } catch (error) {
      openToast('removeProxy', '', selectedItems.length, true)
    } finally {
      retriveProxies()
      setModalLoading(false)
    }
  }, [selectedItems, openToast, retriveProxies])

  /* istanbul ignore next */
  const onUpdatedProxy = useCallback(
    async (proxyName: string, description: string) => {
      setModalLoading(true)
      try {
        let updatedProxy
        if (isWexTheme) {
          updatedProxy = await updateProxyForWex(
            proxyName,
            selectedItems[0].id,
            description
          )
        } else {
          updatedProxy = await updateProxy(
            proxyName,
            selectedItems[0].id,
            description,
            authProvider,
            authStack
          )
        }
        if (updatedProxy) {
          openToast('updateProxy', proxyName, selectedItems.length, false)
        }
        setShowUpdateModal(false)
      } catch (error) {
        openToast('updateProxy', '', selectedItems.length, true)
      } finally {
        setModalLoading(false)
        retriveProxies()
      }
    },
    [selectedItems, openToast, retriveProxies]
  )
  const customNoItems = useMemo(() => {
    const noItemsMessage = hasError
      ? t('ecp-fleet-proxies.unableToLoad', 'Unable to Load Data')
      : proxies.length === 0
        ? t('ecp-fleet-proxies.noProxies', 'No Proxies Found.')
        : t(
            'ecp-fleet-proxies.noResultsFound',
            'No Results Found in Proxies List'
          )

    const clickToAddMessage = hasError
      ? null
      : proxies.length === 0
        ? t(
            'ecp-fleet-proxies.clickToAddProxies',
            'Select the Add button to add a proxy.'
          )
        : t(
            'ecp-fleet-proxies.selectOtherFilters',
            'You can select other filters or adjust your search keywords.'
          )

    return (
      <div
        data-testid={
          hasError ? 'error-proxies-list' : 'error-empty-proxies-list'
        }
      >
        {noItemsMessage === 'Unable to Load Data' ? (
          <ErrorWidget
            message={noItemsMessage}
            onRetry={retriveProxies}
            labelRetry={t('ecp-fleet-proxies.retry', 'Retry')}
          />
        ) : (
          <ErrorWidget message={noItemsMessage} />
        )}
        {clickToAddMessage && (
          <ClickToAddProxy>{clickToAddMessage}</ClickToAddProxy>
        )}
      </div>
    )
  }, [hasError, proxies.length, t])
  const TableData = hasError ? [] : proxyPageData
  return (
    <>
      <Table
        actionArea={actionArea}
        data-testid="proxys-data-table"
        className="proxy-table-br"
        columns={proxyListColumns}
        columnReorder
        data={TableData}
        onSort={handleSort}
        preferences={{
          ...preferences,
          headerNoWrap: true,
          defaultOrder: defaultOrderColumn,
          order,
          sortBy: orderBy
        }}
        onColumnReorder={handleOnColumnReorder}
        loading={isLoading}
        loadingDataLength={5}
        pagination={{
          currentPage: currentPage,
          onPageChange: handlePageChange,
          onPageSizeChange: handlePageSizeChange,
          pageSize: pageSize,
          pageSizeOptions: defaultpageSizeOptions,
          popoverPlacement: 'top-start',
          totalItems
        }}
        rowSelector={'multiSelection'}
        onSelect={handleSelect}
        onSelectAllPageItems={handleSelectAllPageItems}
        rowSelectAllState={selectionState(proxyPageData)}
        component={'table'}
        customNoItems={customNoItems}
        i18n={Retrievei18nItems()}
      />
      <RemoveModal
        data-testid="remove-proxy-modal"
        modalTitle={
          numberOfSelectedItems > 1
            ? t('ecp-fleet-proxies.removeProxies', 'Remove Proxies')
            : t('ecp-fleet-proxies.removeProxy', 'Remove Proxy')
        }
        onDelete={onDeleteProxy}
        selectedItems={selectedItems}
        onClose={() => {
          handleSelectAllOrDeselectAll(false)
          retriveProxies()
          setShowDeleteModal(false)
        }}
        showModal={showDeleteModal}
        isLoading={modalLoading}
      />
      {numberOfSelectedItems > 0 && (
        <UpdateProxyModal
          selectedProxyName={selectedItems[0].proxyName}
          selectedProxyDescr={selectedItems[0].description}
          data-testid="update-proxy-modal"
          modalTitle={t('ecp-fleet-proxies.editProxy', 'Edit a Proxy')}
          onUpdateProxy={onUpdatedProxy}
          onClose={() => {
            handleSelectAllOrDeselectAll(false)
            retriveProxies()
            setShowUpdateModal(false)
          }}
          showModal={showUpdateModal}
          isLoading={modalLoading}
          proxies={proxies}
        />
      )}
      {numberOfSelectedItems > 0 && (
        <ContextualFooter
          data-testid="proxies-list-contextual-footer"
          onCancel={() => {
            handleSelectAllOrDeselectAll(false)
          }}
          numberOfSelectedItems={numberOfSelectedItems}
          selectedItems={selectedItems}
          onRemove={() => {
            setShowDeleteModal(true)
          }}
          isWexTheme={isWexTheme}
          onUpdate={() => {
            setShowUpdateModal(true)
          }}
        />
      )}
    </>
  )
}

export default ProxiesData
