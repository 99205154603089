import React, { useEffect, useState } from 'react'
import { Button, IconFunnel } from '@veneer/core'
import { FilterProxiesProps } from './type'
import { useShellRootContext } from '../../contexts/ShellRoot'

const FilterProxies = (props: FilterProxiesProps) => {
  const { t } = useShellRootContext()
  const { isLoading, searchProxyItems, setShowProxyFilterSidebar } = props

  const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState(window.innerWidth)
    const callback = () => setWindowSize(window.innerWidth)

    useEffect(() => {
      window.addEventListener('resize', callback)
      return () => window.removeEventListener('resize', callback)
    }, [])

    return windowSize
  }

  const resolution = useWindowSize()
  const isMobileView = resolution <= 600

  const filterButton = (isMobileView?: boolean) => {
    return (
      <Button
        appearance="ghost"
        data-testid="proxies-table-filter-button"
        className="fleetProxiesDisableHoverBackground"
        disabled={isLoading}
        leadingIcon={
          <IconFunnel
            data-testid="icon-funnel"
            filled={searchProxyItems.length > 0 ? true : false}
          />
        }
        onClick={() => setShowProxyFilterSidebar(true)}
      >
        {isMobileView ? '' : t('ecp-fleet-proxies.filter', 'Filter')}
        {searchProxyItems.length > 0
          ? ` (` + searchProxyItems.length + `)`
          : ''}
      </Button>
    )
  }

  return (
    <>
      {isMobileView && filterButton(isMobileView)}
      {!isMobileView && filterButton()}
    </>
  )
}

export default FilterProxies
