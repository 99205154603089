import React, { useEffect, useState } from 'react'
import { AddProxyProps } from './type'
import { useShellRootContext } from '../../contexts/ShellRoot'
import {
  Button,
  ContextualMenu,
  IconDocumentPlus,
  IconEllipsis,
  MenuList
} from '@veneer/core'

const AddProxy = (props: AddProxyProps) => {
  const { t } = useShellRootContext()
  const { isLoading, setShowAddProxyModal } = props

  const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState(window.innerWidth)
    const callback = () => setWindowSize(window.innerWidth)

    useEffect(() => {
      window.addEventListener('resize', callback)
      return () => window.removeEventListener('resize', callback)
    }, [])

    return windowSize
  }

  const resolution = useWindowSize()
  const isMobileView = resolution <= 600

  const addButton = (expanded: boolean) => {
    return (
      <Button
        data-testid="add-proxy-button"
        disabled={isLoading}
        leadingIcon={<IconDocumentPlus />}
        onClick={() => {
          setShowAddProxyModal(true)
        }}
        expanded={expanded}
      >
        {t('ecp-fleet-proxies.addBtnTitle', 'Add')}
      </Button>
    )
  }

  const cMenu = (
    <ContextualMenu
      anchorNode={
        <Button
          appearance="secondary"
          leadingIcon={<IconEllipsis />}
        />
      }
    >
      <MenuList>{addButton(true)}</MenuList>
    </ContextualMenu>
  )

  return (
    <>
      {isMobileView && cMenu}
      {!isMobileView && addButton(false)}
    </>
  )
}

export default AddProxy
