/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-imports */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { SideBar, Checkbox, List, ListItem, IconX, Search } from '@veneer/core'
import { useShellRootContext } from '../../contexts/ShellRoot'

import {
  FilterLabel,
  Divider,
  MarginBottom60,
  PrimaryFooter,
  SelectedItemsText,
  ClearAllButton,
  PrimaryFooterContainer
} from './FilterSidebarStyles'
import { ProxyStatus } from '../../utils/constants'
import * as S from './FilterSidebarStyles'

const FilterSidebar = ({ onclose, performFilter, appliedFilter }) => {
  const [searchFilterValue, setSearchFilterValue] = useState<string>('')
  const [showInvalidSearchMsg, setShowInvalidSearchMsg] =
    useState<boolean>(false)
  const { t = (key, defaultValue) => defaultValue || key } =
    useShellRootContext?.() || {}
  const [statusLabels, setStatusLabels] = useState([])

  const handleSearchFilterValue = (value: string) => {
    setSearchFilterValue(value)
  }

  const handleClearSearch = () => {
    setSearchFilterValue('')
    setStatusLabels(ProxyStatus)
    setShowInvalidSearchMsg(false)
  }

  const handleSearch = (e: { preventDefault: () => void }) => {
    e.preventDefault()
    const filterStatusdata = ProxyStatus.filter((proxy) =>
      proxy.label.toLowerCase().includes(searchFilterValue.toLowerCase())
    )
    filterStatusdata.length === 0
      ? setShowInvalidSearchMsg(true)
      : setShowInvalidSearchMsg(false)
    setStatusLabels(filterStatusdata)
  }

  const handleFilterCheck = (e) => {
    e.target.checked
      ? performFilter(e.target.value, 'push')
      : performFilter(e.target.value, 'pop')
  }

  useEffect(() => {
    setStatusLabels(ProxyStatus)
  }, [])

  const handleCheckboxChange = (e: {
    target: EventTarget & HTMLInputElement
  }) => {
    handleFilterCheck(e)
    fillOrRemoveFromArr(e.target)
  }

  const [arr, setArr] = useState<Array<EventTarget & HTMLInputElement>>([])

  const fillOrRemoveFromArr = (target: EventTarget & HTMLInputElement) => {
    target.checked
      ? setArr([...arr, target])
      : setArr(arr.filter((item) => item !== target))

    return null
  }
  /* istanbul ignore next */
  const handleAllUncheck = (): void => {
    for (const value of arr) {
      value.checked = false
      setArr([])
    }
    return null
  }
  const key = (subkey: string): string => {
    return t(
      `ecp-fleet-proxies.${subkey}`,
      subkey.charAt(0).toUpperCase() + subkey.slice(1)
    )
  }
  const mainContent = (
    <>
      <S.FilterSidebarPrimaryTitle data-testid="filter-sidebar-primary-title">
        {t('ecp-fleet-proxies.filter', 'Filter')}
      </S.FilterSidebarPrimaryTitle>
      <S.FilterSidebarPrimaryDesc data-testid="filter-sidebar-description">
        {t(
          'ecp-fleet-proxies.selectFilter',
          'Select filters to view in the list.'
        )}
      </S.FilterSidebarPrimaryDesc>
      <S.SearchFiltersDiv
        data-testid="search-filters-div"
        id="search-filters-div"
        onSubmit={handleSearch}
      >
        <Search
          data-testid="search-filters-box"
          id="search-filters-box"
          className="marginR11 floatL"
          placeholder={t('ecp-fleet-proxies.searchFilters', 'Search Filters')}
          onChange={handleSearchFilterValue}
          onClear={handleClearSearch}
          content={undefined}
          nonce={undefined}
          rel={undefined}
          rev={undefined}
        />
      </S.SearchFiltersDiv>
      {!showInvalidSearchMsg && (
        <S.FilterHolder>
          <S.FilterList id="connectivityList">
            <>
              <FilterLabel>
                {t('ecp-fleet-proxies.connectivity', 'Connectivity')}
              </FilterLabel>
              <Divider></Divider>
            </>
            <List>
              {ProxyStatus &&
                statusLabels.map((statusLabel) => (
                  <ListItem
                    key={statusLabel.label}
                    data-testid="filter-block-2-item-1"
                    id="filter-block-2-item-1"
                    leadingArea={
                      <Checkbox
                        data-testid="filter-block-2-checkbox-1"
                        id={statusLabel.label}
                        className="lablesCheckBox"
                        name="lablesCheckBox"
                        label={key(statusLabel.value.toLowerCase())}
                        value={statusLabel.value.toLowerCase()}
                        onChange={handleCheckboxChange}
                        checked={
                          appliedFilter.includes(
                            statusLabel.value.toLowerCase()
                          )
                            ? true
                            : arr.includes(
                                  arr.filter(
                                    (item) => item.id === statusLabel.label
                                  )[0]
                                )
                              ? true
                              : false
                        }
                      />
                    }
                  ></ListItem>
                ))}
            </List>
          </S.FilterList>
          <MarginBottom60></MarginBottom60>
        </S.FilterHolder>
      )}
      {showInvalidSearchMsg && (
        <div
          data-testid="not-available-span"
          className="not-available-span"
        >
          {t(
            'ecp-fleet-proxies.searchedfilterNotAvailable',
            'Searched filter not available'
          )}
        </div>
      )}
      {appliedFilter.length ? (
        <PrimaryFooter data-testid="filtersidebar-primary-footer">
          <PrimaryFooterContainer>
            <SelectedItemsText data-testid="filtersidebar-selecteditems-text">
              {appliedFilter.length}
              {` `}
              {t('ecp-fleet-proxies.itemsSelected', 'items selected')}
            </SelectedItemsText>
            <ClearAllButton
              data-testid="filtersidebar-clearall-button"
              appearance="ghost"
              leadingIcon={<IconX />}
              onClick={() => {
                handleAllUncheck()
                performFilter('', 'clearall')
              }}
            >
              {t('ecp-fleet-proxies.clearAll', 'Clear all')}
            </ClearAllButton>
          </PrimaryFooterContainer>
        </PrimaryFooter>
      ) : null}
    </>
  )

  const getContent = () => {
    return <>{mainContent}</>
  }

  return (
    <S.FilterSidebarContainer>
      <SideBar
        data-testid="filter-sidebar-container"
        id="filter-sidebar-container"
        behavior="overlay"
        position="end"
        disableBackdropClick
        show={true}
        content={getContent()}
        onClose={() => onclose()}
      />
    </S.FilterSidebarContainer>
  )
}

FilterSidebar.propTypes = {
  onclose: PropTypes.func.isRequired
}

export default FilterSidebar
