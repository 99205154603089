/* eslint-disable @typescript-eslint/no-explicit-any */
import proxieData from '../data/proxies.json'
import { Stack, FleetProxyConnectorSvcClient } from '@jarvis/web-stratus-client'

export const getProxiesList = async (
  authProvider,
  stack: Stack,
  page: number,
  size: number,
  hostHeader: string
): Promise<any> => {
  const client = new FleetProxyConnectorSvcClient(stack, authProvider)
  const response = await client.getProxies(page, size, hostHeader)
  return response.data
}

export const removeProxiesList = async (
  proxyId: string,
  authProvider,
  stack: Stack
): Promise<any> => {
  const client = new FleetProxyConnectorSvcClient(stack, authProvider)
  const response = await client.deleteProxy(proxyId)
  return response
}

export const updateProxy = async (
  proxyName: string,
  proxyId: string,
  description: string,
  authProvider,
  stack: Stack
): Promise<any> => {
  const client = new FleetProxyConnectorSvcClient(stack, authProvider)
  const response = await client.updateProxy(proxyId, proxyName, description)
  return response
}

export const registerProxy = async (
  proxyName: string,
  proxyDescription: string,
  proxyHost: string,
  authProvider,
  stack: Stack
): Promise<any> => {
  const client = new FleetProxyConnectorSvcClient(stack, authProvider)
  const response = await client.registerProxy(
    proxyName,
    proxyHost,
    proxyDescription
  )
  return response
}

export const validateUserCode = async (
  userCode: string,
  authProvider,
  stack: Stack
): Promise<any> => {
  const client = new FleetProxyConnectorSvcClient(stack, authProvider)
  const response = await client.verifyUserCode(userCode)
  return response
}

export const approveUserCodeForPairing = async (
  userCode: string,
  authProvider,
  stack: Stack
): Promise<any> => {
  const client = new FleetProxyConnectorSvcClient(stack, authProvider)
  const stratus_user_token = await authProvider.getAccessToken()
  const response = await client.approveUserCode(
    userCode,
    stratus_user_token,
    'granted'
  )
  return response
}
// for wex
let currentProxiesData = proxieData

export const getProxiesForWex = async (): Promise<any> => {
  return currentProxiesData
}

export const removeProxiesForWex = async (id: string): Promise<any> => {
  currentProxiesData = currentProxiesData.filter((item) => item.id !== id)
  return currentProxiesData
}
/* istanbul ignore next */
export const updateProxyForWex = async (
  proxyName: string,
  proxyId: string,
  description: string
): Promise<any> => {
  currentProxiesData = currentProxiesData.map((item) => {
    if (item.id === proxyId) {
      item.proxyName = proxyName
      item.description = description
      item.lastUpdated = new Date().toISOString()
    }
    return item
  })
  return currentProxiesData
}
export const registerProxyForWex = async (
  proxyName: string,
  proxyDescription: string,
  proxyHost: string
): Promise<any> => {
  const newProxy = {
    id: 'cfc020f6-d2a1-4a0e-8b65-4b9f01015675',
    tenantId: '9119189a-6845-4782-9ec4-8a897e46bed5',
    proxyName: proxyName,
    proxyType: 'wex',
    proxyState: {
      state: 'CREATED'
    },
    connectionState: {
      status: 'ONLINE'
    },
    hostName: proxyHost,
    devices: 0,
    description: proxyDescription,
    dateAdded: new Date().toISOString(),
    lastUpdated: new Date().toISOString()
  }
  currentProxiesData.push(newProxy)
  return currentProxiesData
}
