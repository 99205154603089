import React, { useState } from 'react'
import GenericModal from '../GenericModal'
import { TextBox, IconCheckmarkCircle } from '@veneer/core'
import {
  AddProxyModalDescr,
  AddProxyModalFooter,
  AddProxyModalNote,
  RegProxyName,
  SuccessIconStyle
} from './style'
import { useShellRootContext } from '../../../contexts/ShellRoot'

import dayjs from 'dayjs'
import { validateUserCode } from '../../../utils/api'

const AddProxyModal = ({
  modalTitle,
  onClose,
  onAddProxy,
  showModal,
  isLoading,
  proxyVerify,
  proxyConnect,
  proxies,
  userCode,
  isProxyRegisteredAndConnect,
  proxyRegistrationAndConnectionStatus,
  isWexTheme
}) => {
  const [proxyCode, setProxyCode] = useState<string>(userCode)
  const [proxyVerified, setProxyVerified] = useState<boolean>(proxyVerify)
  const [proxyConnected, setProxyConnected] = useState<boolean>(proxyConnect)
  const [hostName, setHostName] = useState<string>('')
  const [pinCode, setPinCode] = useState<string>('')
  const [proxyNameLatest, setProxyNameLatest] = useState<string>('')
  const [isProxyAvailable, setIsProxyAvailable] = useState<boolean>(false)
  const [isProxyRegisteredAndConnected, setIsProxyRegisteredAndConnected] =
    useState<boolean>(isProxyRegisteredAndConnect)
  const [proxyDescription, setProxyDescription] = useState<string>()
  const [hasError, setHasError] = useState<boolean>(false)
  const [buttonLoading, setButtonLoading] = useState<boolean>(isLoading)

  const { t = (key, defaultValue) => defaultValue || key, interfaces } =
    useShellRootContext?.() || {}
  const authStack = interfaces?.v1?.app?.getAuthStack?.()
  const authProvider = interfaces?.v2.authProvider || {}
  /* istanbul ignore next */
  const verifyProxyCode = async () => {
    if (proxyCode === '' || proxyCode === null) {
      setHasError(true)
    } else {
      setButtonLoading(true)
      try {
        if (isWexTheme) {
          setPinCode(proxyCode)
          setHostName('WEX-PRINT-PROXY')
          setProxyNameLatest('WEX-PRINT-PROXY' + dayjs(new Date()))
        } else {
          const resp = await validateUserCode(
            proxyCode,
            authProvider,
            authStack
          )
          setPinCode(resp?.data?.user_code)
          setHostName(resp?.data?.device_info)
          setProxyNameLatest(resp?.data?.device_info + '_' + dayjs(new Date()))
        }
        setProxyVerified(true)
        setProxyCode('')
      } catch (error) {
        setHasError(true)
      } finally {
        setButtonLoading(false)
      }
    }
  }
  /* istanbul ignore next */
  const verifyProxyHost = async () => {
    const res = proxies.filter(
      (it) => it.hostName.toLowerCase() === hostName.toLowerCase()
    )
    if (res && res.length > 0) {
      setProxyNameLatest(res[0].proxyName)
      setIsProxyRegisteredAndConnected(true)
    } else {
      setHasError(false)
      setProxyConnected(true)
      setIsProxyRegisteredAndConnected(false)
    }
  }
  /* istanbul ignore next */
  const handleContinueClick = async () => {
    if (!proxyVerified) {
      verifyProxyCode()
    } else {
      setButtonLoading(true)
      if (proxyConnected) {
        if (proxyNameLatest !== '') {
          const res = proxies.filter(
            (it) => it.proxyName.toLowerCase() === proxyNameLatest.toLowerCase()
          )
          if (res && res.length > 0) {
            setHasError(true)
            setButtonLoading(false)
            setIsProxyAvailable(true)
          } else {
            await onAddProxy(
              proxyNameLatest,
              proxyDescription,
              hostName,
              pinCode
            )
            setProxyVerified(false)
            setProxyConnected(false)
            setProxyNameLatest(hostName + '_' + dayjs(new Date()))
            setProxyDescription('')
            setIsProxyAvailable(false)
            setHasError(false)
            setButtonLoading(false)
          }
        } else {
          setHasError(true)
          setButtonLoading(false)
        }
      } else {
        setButtonLoading(true)
        await verifyProxyHost()
        if (isProxyRegisteredAndConnected) {
          await proxyRegistrationAndConnectionStatus(proxyNameLatest, pinCode)
          setProxyVerified(false)
          setProxyConnected(false)
          setIsProxyAvailable(false)
          setHasError(false)
        }
        setButtonLoading(false)
      }
    }
  }
  /* istanbul ignore next */
  const handleHelperText = () => {
    if (hasError) {
      if (proxyConnected) {
        if (proxyNameLatest === '') {
          return t('ecp-fleet-proxies.nameRequired', 'Proxy name is required.')
        } else if (isProxyAvailable) {
          return t(
            'ecp-fleet-proxies.proxyNameAvailable',
            "The proxy name you've entered is already in use. Enter a different name."
          )
        } else {
          setHasError(false)
        }
      } else {
        if (proxyCode === '' || proxyCode === null) {
          return t('ecp-fleet-proxies.codeRequired', 'Code is required.')
        } else {
          return t(
            'ecp-fleet-proxies.invalidCode',
            'Invalid code. Check the code on the HP Fleet Proxy and then try again.'
          )
        }
      }
    }
  }

  /* istanbul ignore next */
  const handleModalTitle = () => {
    if (proxyVerified) {
      if (proxyConnected || isProxyRegisteredAndConnected) {
        return t('ecp-fleet-proxies.proxyConnected', 'Proxy Connected')
      } else {
        return t('ecp-fleet-proxies.proxyVerified', 'Proxy Verified')
      }
    } else {
      return modalTitle
    }
  }

  /* istanbul ignore next */
  const proceedBtnLabel = () => {
    if (proxyVerified) {
      if (proxyConnected || isProxyRegisteredAndConnected)
        return t('ecp-fleet-proxies.done', 'Done')
      else return t('ecp-fleet-proxies.allow', 'Allow')
    } else {
      return t('ecp-fleet-proxies.verify', 'Verify')
    }
  }
  /* istanbul ignore next */
  const headerDescription = () => {
    if (proxyVerified) {
      if (proxyConnected || isProxyRegisteredAndConnected) {
        return (
          <>
            <SuccessIconStyle>
              <IconCheckmarkCircle
                data-test-id="success-icon"
                size={53}
                color="green6"
              />
            </SuccessIconStyle>
            {isProxyRegisteredAndConnected && (
              <>
                <div>
                  {t('ecp-fleet-proxies.proxyConnectedDescr', 'Your proxy')}
                  <span className="hostName">{hostName}</span>
                  {t(
                    'ecp-fleet-proxies.proxyConnectedDescr3',
                    'is already registered and connected.'
                  )}
                </div>
                <RegProxyName>{proxyNameLatest}</RegProxyName>
              </>
            )}
            {!isProxyRegisteredAndConnected && (
              <div>
                {t('ecp-fleet-proxies.proxyConnectedDescr', 'Your proxy')}
                <span className="hostName">{hostName}</span>
                {t(
                  'ecp-fleet-proxies.proxyConnectedDescr2',
                  'is now connected. You can modify the proxy name and the description.'
                )}
              </div>
            )}
          </>
        )
      } else {
        return (
          <div>
            {t('ecp-fleet-proxies.proxyConnectedDescr', 'Your proxy')}
            <span className="hostName">{hostName}</span>
            {t(
              'ecp-fleet-proxies.proxyVerifiedDescr',
              'has been verified. If this is the proxy you are trying to connect to, allow the connection.'
            )}
          </div>
        )
      }
    } else {
      return t(
        'ecp-fleet-proxies.verifyModalDescription',
        'In order to connect your proxy devices to the HP Command Center, you need to verify a code. Enter the verification code displayed on the HP Fleet Proxy installed PC.'
      )
    }
  }

  /* istanbul ignore next */
  return (
    <GenericModal
      dataTestId={'add-proxy-modal'}
      id={'add-proxy-modal'}
      onClose={onClose}
      show={showModal}
      customModalWidth={'612px'}
      customModalDescWidth={'327px'}
      header={{
        title: handleModalTitle(),
        dataTestId: 'add-proxy-modal-title'
      }}
      footer={{
        buttons: {
          cancel: {
            className: proxyConnected ? 'hideButton' : '',
            label: t('ecp-fleet-proxies.cancel', 'Cancel'),
            onClick: () => {
              onClose()
              setProxyCode('')
              setProxyVerified(false)
              setHasError(false)
            },
            dataTestId: 'add-proxy-cancel-btn'
          },
          proceed: {
            label: proceedBtnLabel(),
            onClick: handleContinueClick,
            disabled: buttonLoading,
            loading: buttonLoading,
            dataTestId: 'add-proxy-btn'
          }
        }
      }}
    >
      <>
        <AddProxyModalDescr>{headerDescription()}</AddProxyModalDescr>
        {!proxyVerified && !proxyConnected && (
          <>
            <AddProxyModalFooter data-testid="verify-proxy-code-block">
              <TextBox
                label={t('ecp-fleet-proxies.verifyCodeLabel', 'Code')}
                data-testid="enter-proxy-code"
                error={hasError}
                placeholder={t(
                  'ecp-fleet-proxies.verifyCodePlaceHolder',
                  'Enter a code'
                )}
                value={proxyCode}
                required
                separateLabel
                helperText={handleHelperText()}
                onChange={(value) => {
                  setProxyCode(value)
                }}
              />
            </AddProxyModalFooter>
            <AddProxyModalNote data-testid="add-proxy-modal-note">
              {t(
                'ecp-fleet-proxies.verifyModalNote',
                'Note: If you need a verification code, install HP Fleet Proxy on the Web Jetadmin PC and generate the code.'
              )}
            </AddProxyModalNote>
          </>
        )}
        {proxyConnected && proxyVerified && !isProxyRegisteredAndConnected && (
          <>
            {' '}
            <AddProxyModalFooter data-testid="add-proxy-block">
              <TextBox
                label={t('ecp-fleet-proxies.proxyName', 'Proxy Name')}
                data-testid="add-proxy-name"
                error={hasError}
                placeholder={t('ecp-fleet-proxies.enterName', 'Enter a name')}
                value={proxyNameLatest}
                required
                separateLabel
                helperText={handleHelperText()}
                onChange={(value) => {
                  setProxyNameLatest(value)
                }}
              />
            </AddProxyModalFooter>
            <AddProxyModalFooter>
              <TextBox
                data-testid="add-proxy-description"
                label={t('ecp-fleet-proxies.description', 'Description')}
                placeholder={t(
                  'ecp-fleet-proxies.enterDescr',
                  'Enter description'
                )}
                separateLabel
                value={proxyDescription}
                onChange={(value) => {
                  setProxyDescription(value)
                }}
              />
            </AddProxyModalFooter>
          </>
        )}
      </>
    </GenericModal>
  )
}

export default AddProxyModal
