import styled from 'styled-components'
import primitives from '@veneer/primitives'
import { Select } from '@veneer/core'

export const ActionSelect = styled(Select)`
  && {
    min-width: 224px;
    background-color: white;
    border-radius: 20px;
    @media screen and (max-width: ${primitives.layout.breakpoint1}px) {
      min-width: initial;
      width: 100%;
    }
  }
`

export const ProxyName = styled.span`
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
`

export const ProxyStatus = styled.label`
  display: flex;
  align-items: center;
  gap: 5px;
`
export const ClickToAddProxy = styled.div`
  margin-top: 8px;
`
export const ColumnPiece = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 20px;
  align-items: center;
  white-space: nowrap;
`

export const ContextualMenuArea = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  top: 0;
  left: 0;
`

export const UserInfo = styled.div`
  position: relative;
  z-index: 2;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const ReorderColumnPortal = styled.div`
  .vn-modal--footer div {
    flex-direction: row-reverse;
    gap: 12px;
  }
`
